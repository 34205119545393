<template>
	<v-sheet class="project" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex">
						<p class="my-auto font-level-3-bold">
							<span class="fw-500">
								<b><ShowValue :object="project" object-key="name" label="project name"></ShowValue></b>
								<v-chip color="cyan" class="ml-2" label text-color="white">{{ project.barcode }}</v-chip>
								<template v-if="project.status == 1">
									<v-chip class="ml-2" color="orange" text-color="white" label> Draft </v-chip>
								</template>
								<template v-if="project.status == 2">
									<v-chip class="ml-2" color="blue" text-color="white" label> In-Progress </v-chip>
								</template>
								<template v-if="project.status == 3">
									<v-chip class="ml-2" color="green" text-color="white" label> Completed </v-chip>
								</template>
							</span>
						</p>
						<v-spacer></v-spacer>
						<div v-if="project.status == 1">
							<v-btn
								v-if="getPermission('project:update')"
								depressed
								tile
								class="blue darken-4 mr-2 my-auto"
								color="white--text "
								v-on:click="doAction('edit')"
							>
								<v-icon class="mr-1">mdi-pencil</v-icon>
								Edit
							</v-btn>
						</div>
						<div>
							<v-btn
								v-if="getPermission('project:delete')"
								depressed
								tile
								class="blue darken-4 mr-2 my-auto"
								color="white--text "
								v-on:click="doAction('delete')"
							>
								<v-icon class="mr-1">mdi-delete</v-icon>
								Delete
							</v-btn>
						</div>

						<div>
							<template v-if="getPermission('project:update')">
								<v-btn
									v-if="project.status == 1"
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text "
									v-on:click="startProjectDialog = true"
								>
									Start
								</v-btn>
							</template>
						</div>
						<div>
							<template v-if="getPermission('project:update')">
								<v-btn
									v-if="project.status == 2"
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text "
									v-on:click="completeProjectDialog = true"
								>
									Complete
								</v-btn>
							</template>
						</div>
						<div>
							<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
						</div>
					</div>
					<div class="d-flex mt-4">
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600">Customer # : </span>
								<span class="fw-500">
									<ShowValue :object="customer" object-key="barcode" label="customer #"></ShowValue
								></span>
							</div>
							<div class="mt-2">
								<span class="fw-600 mr-2">Customer Name : </span>
								<span class="fw-500">
									<ShowValue :object="customer" object-key="display_name" label="customer name"></ShowValue
								></span>
							</div>
						</div>
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600">Start Date : </span>
								<span class="fw-500">
									<ShowValue
										:object="project"
										object-key="start_date_formatted"
										label="Start Date"
									></ShowValue>
								</span>
							</div>
							<div class="mt-2">
								<span class="fw-600"> End Date : </span>
								<span class="fw-500">
									<ShowValue :object="project" object-key="end_date_formatted" label="End Date"></ShowValue>
								</span>
							</div>
						</div>
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600"> Property # : </span>
								<span class="fw-500"
									><ShowValue :object="property" object-key="barcode" label="Property #"></ShowValue
								></span>
							</div>
							<div class="mt-2">
								<span class="fw-600 mr-2">Property Name : </span>
								<span>
									<ShowValue :object="property" object-key="full_address" label="Property Name"></ShowValue>
								</span>
							</div>
						</div>
					</div>
					<div class="d-flex">
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600">Actual Start Date : </span>
								<span class="fw-500">
									<ShowValue
										:object="project"
										object-key="actual_start_date_formatted"
										label="actual start date"
									></ShowValue
								></span>
							</div>
						</div>
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600">Actual End Date : </span>
								<span class="fw-500">
									<ShowValue
										:object="project"
										object-key="actual_end_date_formatted"
										label="actual end date"
									></ShowValue
								></span>
							</div>
						</div>
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600">Site Number : </span>
								<span class="fw-500">
									<ShowValue :object="project" object-key="site_number" label="Site Number"></ShowValue
								></span>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div class="mt-2">
				<v-tabs
					v-model="projectTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Details</v-tab
					>
					<v-tab href="#members"> <v-icon small left>mdi-share-outline</v-icon> Staffs</v-tab>
					<v-tab href="#asset"> <v-icon small left>mdi-share-outline</v-icon> Asset</v-tab>
					<v-tab href="#asset-stock"> <v-icon small left>mdi-share-outline</v-icon> Asset Stock</v-tab>
					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files </v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
				</v-tabs>

				<v-tabs-items v-model="projectTab">
					<v-tab-item value="detail">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Project Details
									</v-flex>
								</v-layout>
								<table width="100%" class="detail-table scattered-table">
									<!-- <tr>
										<th width="250px">Start Date</th>
										<td>
											<ShowValue
												:object="project"
												object-key="start_date_formatted"
												label="start date"
											></ShowValue>
										</td>
										<th width="250px">End Date</th>
										<td>
											<ShowValue
												:object="project"
												object-key="end_date_formatted"
												label="end date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Actual Start Date</th>
										<td>
											<ShowValue
												:object="project"
												object-key="actual_start_date_formatted"
												label="actual start date"
											></ShowValue>
										</td>
										<th width="250px">Actual End Date</th>
										<td>
											<ShowValue
												:object="project"
												object-key="actual_end_date_formatted"
												label="actual end date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th>Site Number</th>
										<td colspan="3">
											<ShowValue :object="project" object-key="site_number" label="site number"></ShowValue>
										</td>
									</tr> -->
									<tr>
										<th width="150px">Description</th>
										<td>
											<ShowValue :object="project" object-key="description" label="description"></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="150px">Remark</th>
										<td>
											<ShowValue :object="project" object-key="remark" label="remark"></ShowValue>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="asset">
						<CheckoutListing
							v-if="project && project.id && projectTab == 'asset'"
							class="mx-4"
							relation-type="project"
							:relation-type-id="project.id"
							type="asset"
							title="Assets"
						></CheckoutListing>
					</v-tab-item>
					<v-tab-item value="members">
						<v-simple-table class="bt-table mt-3">
							<thead>
								<tr>
									<th>
										<v-layout>
											<v-flex class="text-left"> Image </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Staff # </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Email </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Display Name </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Identification Number </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Phone Number </v-flex>
										</v-layout>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(td, index) in project.members" :key="index">
									<td>
										<ImageTemplate circle style="max-width: 50px" :src="td.member.image"></ImageTemplate>
									</td>
									<td>
										<Chip :text="td.member.barcode" color="cyan"></Chip>
									</td>
									<td>
										<ShowValue :object="td" object-key="member.email" label="Email"></ShowValue>
									</td>
									<td>
										<ShowValue :object="td" object-key="member.display_name" label="Display Name"></ShowValue>
									</td>
									<td>
										<ShowValue
											:object="td"
											object-key="member.id_number"
											label="Identification Number"
										></ShowValue>
									</td>
									<td>
										<ShowValue :object="td" object-key="member.phone_number" label="Phone Number"></ShowValue>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</v-tab-item>
					<v-tab-item value="asset-stock">
						<CheckoutListing
							v-if="project && project.id && projectTab == 'asset-stock'"
							class="mx-4"
							relation-type="project"
							:relation-type-id="project.id"
							type="asset-stock"
							title="Asset Stocks"
						></CheckoutListing>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="projectTab == 'file'"
							class="mx-4"
							type-text="Project Files"
							type="project"
							:type-uuid="uuid"
							:type-id="projectId"
						>
						</Files>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="projectTab == 'event'"
							class="mx-4"
							type-text="Customer"
							type="project"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Customer"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					project.activated
						? 'To preserve associated records, you could de-activate the Customer instead.'
						: null
				"
			>
				<template v-if="project.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Customer"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Customer"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
			<Dialog :dialog="startProjectDialog" :dialog-width="640">
				<template v-slot:title> Start Project</template>
				<template v-slot:body>
					<v-row class="delete-dialog">
						<v-col md="2" class="py-0 text-right my-auto">
							<span class="svg-icon svg-icon-lg delete-confirm-icon">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
								<!--end::Svg Icon-->
							</span>
						</v-col>
						<v-col md="10" class="py-0 my-auto">
							<p class="btx-p m-0">
								Are you sure you want to Start
								<span class="red--text font-bolder lighten-1">{{ property.name }}</span> Project ?
							</p>
						</v-col>
					</v-row>
				</template>
				<template v-slot:action>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="pageLoading"
						depressed
						color="green lighten-1"
						tile
						v-on:click="startProject"
					>
						Yes! Start
					</v-btn>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="startProjectDialog = false">
						No, Close
					</v-btn>
				</template>
			</Dialog>
			<Dialog :dialog="completeProjectDialog" :dialog-width="640">
				<template v-slot:title> Complete Project</template>
				<template v-slot:body>
					<v-row class="delete-dialog">
						<v-col md="2" class="py-0 text-right my-auto">
							<span class="svg-icon svg-icon-lg delete-confirm-icon">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
								<!--end::Svg Icon-->
							</span>
						</v-col>
						<v-col md="10" class="py-0 my-auto">
							<p class="btx-p m-0">
								Are you sure you want to Complete
								<span class="red--text font-bolder lighten-1">{{ property.name }}</span> Project ?
							</p>
						</v-col>
					</v-row>
				</template>
				<template v-slot:action>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="pageLoading"
						depressed
						color="green lighten-1"
						tile
						v-on:click="completeProject"
					>
						Yes! Start
					</v-btn>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="completeProjectDialog = false">
						No, Close
					</v-btn>
				</template>
			</Dialog>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GetProject, StartProject, CompleteProject } from "@/core/lib/project.lib";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { isEmpty } from "lodash";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import EventListing from "@/view/components/EventListing";
import CheckoutListing from "@/view/components/CheckoutListing";
import Dialog from "@/view/components/Dialog.vue";
import Chip from "@/view/components/Chip";
import ImageTemplate from "@/view/components/Image";
import Files from "@/view/components/Files";

export default {
	name: "project-detail",
	title: "Detail Customer",
	data() {
		return {
			uuid: null,
			barcode: null,
			customer: {},
			property: {},
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			deleteText: null,
			contact_person: [],
			deleteURL: null,
			startProjectDialog: false,
			completeProjectDialog: false,
			formValid: true,
			personModel: {},
			deleteDialog: false,
			addressDialog: false,
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: false,
			pageLoading: false,
			projectTab: "detail",
			project: {},
			projectId: 0,
		};
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "project-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "new-address":
					this.addressDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		startProject() {
			StartProject(this.uuid)
				.then(() => {
					this.getProject();
					this.startProjectDialog = false;
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		completeProject() {
			CompleteProject(this.uuid)
				.then(() => {
					this.getProject();
					this.completeProjectDialog = false;
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		getProject() {
			GetProject(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.project = data;
					this.projectId = data.id;
					this.customer = data.contact;
					this.property = data.property;
					this.deleteText = `#${data.barcode}`;
					this.deleteURL = `project/${data.uuid}`;
					this.activateText = `${data.barcode}`;
					this.activateURL = `project/${data.uuid}/activate`;
					this.deactivateText = `${data.barcode}`;
					this.deactivateURL = `project/${data.uuid}/de-activate`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Project", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		addPerson(person) {
			this.personDialog = true;
			this.personModel = new Object();
			if (person && !isEmpty(person)) {
				this.personModel = new Object({
					id: person.id || null,
					uuid: person.uuid || null,
					salutation: person.salutation || null,
					first_name: person.first_name || null,
					last_name: person.last_name || null,
					email: person.email || null,
					mobile: person.phone || null,
					display_name: person.display_name || null,
					designation: person.designation || null,
					primary: person.primary || null,
				});
			}
		},
	},
	components: {
		Files,
		ShowValue,
		EventListing,
		DeleteTemplate,
		ActivateTemplate,
		ImageTemplate,
		Dialog,
		Chip,
		CheckoutListing,
		DeactivateTemplate,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Contacts", disabled: true },
			{ text: "Customer", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getProject();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
